import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import card_img from "../../assets/img/hm1.avif";
const CustomSlider = ({ slidesToShow }) => {
  const cardData = [
    {
      id: 1,
      imgSrc: card_img,
      title: "New Apartments",
      price: "35,000",
      description:
        "Beautiful Huge 1 Family House In Heart Of Westbury. Newly Renovated With New Wood",
    },
    {
      id: 2,
      imgSrc: card_img,
      title: "New Apartments",
      price: "35,000",
      description:
        "Beautiful Huge 1 Family House In Heart Of Westbury. Newly Renovated With New Wood",
    },
    {
      id: 3,
      imgSrc: card_img,
      title: "New Apartments",
      price: "35,000",
      description:
        "Beautiful Huge 1 Family House In Heart Of Westbury. Newly Renovated With New Wood",
    },
    {
      id: 4,
      imgSrc: card_img,
      title: "New Apartments",
      price: "35,000",
      description:
        "Beautiful Huge 1 Family House In Heart Of Westbury. Newly Renovated With New Wood",
    },
    {
      id: 5,
      imgSrc: card_img,
      title: "New Apartments",
      price: "35,000",
      description:
        "Beautiful Huge 1 Family House In Heart Of Westbury. Newly Renovated With New Wood",
    },
    // Add more card data as needed
  ];



  const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={`${className} custom-arrow custom-prev`} onClick={onClick}>
        {/* Customize your Previous Arrow icon here */}
        <i className="fas fa-chevron-left"></i>
      </div>
    );
  };

  const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={`${className} custom-arrow custom-next`} onClick={onClick}>
        {/* Customize your Next Arrow icon here */}
        <i className="fas fa-chevron-right"></i>
      </div>
    );
  };


  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 2000,
    prevArrow: <CustomPrevArrow />, // Custom Previous Arrow Component
    nextArrow: <CustomNextArrow />, // Custom Next Arrow Component
  };

  return (
    <div className="Exited_swiper">
      <Slider {...settings}>
        {cardData.map((card, index) => (
          <div key={index} className="ltn__product-item ltn__product-item-4 text-center---">
            <div className="product-img go-top">
              <a href="#/product-details" tabIndex={0}>
                <img src="/assets/img/product-3/1.jpg" alt="#" />
              </a>
              <div className="product-badge">
                <ul>
                  <li className="sale-badge bg-green">For Rent</li>
                </ul>
              </div>
              <div className="product-img-location-gallery">
                <div className="product-img-location">
                  <ul>
                    <li>
                      <a href="#/contact" tabIndex={0}>
                        <i className="flaticon-pin" /> Belmont Gardens, Chicago
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="product-img-gallery go-top">
                  <ul>
                    <li>
                      <a href="#/product-details" tabIndex={0}>
                        <i className="fas fa-camera" /> 4
                      </a>
                    </li>
                    <li>
                      <a href="#/product-details" tabIndex={0}>
                        <i className="fas fa-film" /> 2
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="product-info">
              <div className="product-price">
                <span>
                  $34,900<label>/Month</label>
                </span>
              </div>
              <h2 className="product-title go-top">
                <a href="#/product-details" tabIndex={0}>
                  New Apartment Nice View
                </a>
              </h2>
              <div className="product-description">
                <p>
                  Beautiful Huge 1 Family House In Heart Of <br />
                  Westbury. Newly Renovated With New Wood
                </p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>

  );
};

export default CustomSlider;
