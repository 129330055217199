import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import CustomSlider from './CustomSlider';
import ProductSliderV4 from './product-slider-v4';

class BannerV6 extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedOption: '',
			isDropdownOpen: false,
		};
		this.options = ["Hyderabad", "Vikarabad", "Nellore", "Tirupati", "Visakhapatnam"];
	}

	handleInputChange = (event) => {
		this.setState({ selectedOption: event.target.value });
	};

	handleOptionSelect = (value) => {
		this.setState({ selectedOption: value, isDropdownOpen: false });
	};

	toggleDropdown = () => {
		this.setState((prevState) => ({ isDropdownOpen: !prevState.isDropdownOpen }));
	};

	render() {
		const { selectedOption, isDropdownOpen } = this.state;

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="ltn__slider-area ltn__slider-4 position-relative  ltn__primary-bg">
			<div className="ltn__slide-one-active----- slick-slide-arrow-1----- slick-slide-dots-1----- arrow-white----- ltn__slide-animation-active">

				<video autoPlay muted loop id="myVideo">
					<source src={publicUrl + "assets/media/3.mp4"} type="video/mp4" />
				</video>

				<div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-7 bg-image--- bg-overlay-theme-black-30" data-bs-bg={publicUrl + "assets/img/slider/41.jpg"}>
					<div className="ltn__slide-item-inner text-center">
						<div className="container">
							<div className="row">
								<div className="col-lg-12 align-self-center">
									<div className="slide-item-car-dealer-form">
										<div className="section-title-area ltn__section-title-2 text-center">
											<h1 className="section-title  text-color-white">Find Your <span className="ltn__secondary-color-3">Perfect</span> Properties</h1>
										</div>
										<div className="ltn__car-dealer-form-tab">
											<div className="ltn__tab-menu  text-uppercase text-center">
												<div className="nav">
													<a className="active show" data-bs-toggle="tab" href="#ltn__form_tab_1_1"><i className="fa-solid fa-cart-shopping" />Buy</a>
													<a data-bs-toggle="tab" href="#ltn__form_tab_1_2" className><i className="fa-solid fa-truck-moving" />Rent</a>
													<a data-bs-toggle="tab" href="#ltn__form_tab_1_3" className><i className="fas fa-home" />Commercial</a>
													<a data-bs-toggle="tab" href="#ltn__form_tab_1_4" className><i className="fa-solid fa-hotel" />PG/Co-Living</a>
													<a data-bs-toggle="tab" href="#ltn__form_tab_1_5" className><i className="fas fa-home" />Plots</a>
												</div>
											</div>
											<div className="tab-content pb-10">
												<div className="tab-pane fade active show" id="ltn__form_tab_1_1">
													<div className="car-dealer-form-inner">
														<form action="#" className="ltn__car-dealer-form-box row">
															<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-3 col-md-6">
																<select className="nice-select">
																	<option>Property Type</option>
																	<option>Apartment</option>
																	<option>Co-op</option>
																	<option>Condo</option>
																	<option>Single Family Home</option>
																</select>
															</div>
															<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-meter col-lg-3 col-md-6">
																<input
																	type="text"
																	value={selectedOption}
																	onChange={this.handleInputChange}
																	placeholder="Location"
																	onClick={this.toggleDropdown}
																	className="custom-select nice-select"
																/>
																{isDropdownOpen && (
																	<ul className="dropdown-list">
																		{this.options
																			.filter(option => option.toLowerCase().includes(selectedOption.toLowerCase()))
																			.map((option, index) => (
																				<li key={index} onClick={() => this.handleOptionSelect(option)}>
																					{option}
																				</li>
																			))}
																	</ul>
																)}
															</div>
															<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-3 col-md-6">
																<select className="nice-select">
																	<option>Sub Location</option>
																	<option>Madhapur</option>
																	<option>Gachibowli</option>
																	<option>Manikonda</option>
																	<option>Uppal</option>
																	<option>Jubilee Hills</option>
																	<option>Miyapur</option>
																	<option>Old City</option>
																</select>
															</div>
															<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-3 col-md-6">
																<div className="btn-wrapper text-center mt-0 go-top">
																	{/* <button type="submit" class="btn theme-btn-1 btn-effect-1 text-uppercase">Search Inventory</button> */}
																	<Link to="/shop-right-sidebar" className="btn theme-btn-1 btn-effect-1 text-uppercase">Search</Link>
																</div>
															</div>
														</form>
													</div>
												</div>
												<div className="tab-pane fade" id="ltn__form_tab_1_2">
													<div className="car-dealer-form-inner">
														<form action="#" className="ltn__car-dealer-form-box row">
															<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-3 col-md-6">
																<select className="nice-select">
																	<option>Property Type</option>
																	<option>Apartment</option>
																	<option>Co-op</option>
																	<option>Condo</option>
																	<option>Single Family Home</option>
																</select>
															</div>
															<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-meter col-lg-3 col-md-6">
																<select className="nice-select">
																	<option>Location</option>
																	<option>Hyderabad</option>
																	<option>Vikarabad</option>
																	<option>Nellore</option>
																	<option>Tirupati</option>
																	<option>Visakhapatnam</option>
																</select>
															</div>
															<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-3 col-md-6">
																<select className="nice-select">
																	<option>Sub Location</option>
																	<option>Madhapur</option>
																	<option>Gachibowli</option>
																	<option>Manikonda</option>
																	<option>Uppal</option>
																	<option>Jubilee Hills</option>
																	<option>Miyapur</option>
																	<option>Old City</option>
																</select>
															</div>
															<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-3 col-md-6">
																<div className="btn-wrapper text-center mt-0 go-top">
																	{/* <button type="submit" class="btn theme-btn-1 btn-effect-1 text-uppercase">Search Inventory</button> */}
																	<Link to="/shop-right-sidebar" className="btn theme-btn-1 btn-effect-1 text-uppercase">Search</Link>
																</div>
															</div>
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					
					</div>
				
					
					<div className='triple-carousel py-2'>
					<CustomSlider slidesToShow={1}/>
					<CustomSlider slidesToShow={1}/>
					<CustomSlider slidesToShow={1}/>
				</div>
				</div>

				
				{/* <div className='row'>
						<div className='col-md-10 offset-md-1'>
						
				
						</div>
					</div> */}

			</div>
		</div>
	}
}

export default BannerV6